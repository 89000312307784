import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NbAccessChecker} from '@nebular/security';
import {AuthService} from './services/auth.service';
import {AlertController, IonSplitPane, MenuController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {QuestionsService} from './services/questions.service';
import {Router} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  seminars$: Observable<any[]> = of([]);

  public appPages = [
    {title: 'home', url: '/home', icon: 'home', permission: 'view', resource: 'home'},
    {
      title: 'Questions',
      url: '/questions',
      icon: 'paper-plane',
      permission: 'control',
      resource: 'new-questions'
    },
  ];
  ionSplitPaneVisible = false;
  private destroy$ = new Subject();

  constructor(public aclChecker: NbAccessChecker,
              public authService: AuthService,
              public translate: TranslateService,
              private alertCtrl: AlertController,
              public menu: MenuController,
              public platform: Platform,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private questionsService: QuestionsService) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.setAppLanguageBasedOnPreference();
    const seminars = this.questionsService.getSeminars();
    seminars.pipe(takeUntil(this.destroy$)).subscribe();

    this.seminars$ = seminars.pipe(tap((d) => {
    }));

  }

  logout() {
    this.alertCtrl.create({
      header: this.translate.instant('logout'),
      message: this.translate.instant('logout.confirm'),
      buttons: [
        {
          text: this.translate.instant('cancel'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('logout'),
          cssClass: 'alert-button-confirm-logout',
          handler: () => {
            this.menu.close('main-content').then(() => {
              this.authService.logout();
            });
          }
        }]
    }).then(alert => alert.present());
  }

  toggleIsSeminarActive(event: any, seminarId: string, oneSeminar) {
    const isSeminarActive = event.detail.checked;
    return this.questionsService.toggleSeminar(seminarId, isSeminarActive, oneSeminar).subscribe();
  }

  setAppLanguageBasedOnPreference() {
    const preferredLanguage = localStorage.getItem('preferredLanguage') ?? 'en';
    this.translate.use(preferredLanguage);
  }

  changeLanguage(event) {
    this.menu.close('side-menu').then(() => {
      const preferredLanguage = event.detail.value;
      this.translate.use(preferredLanguage);
      localStorage.setItem('preferredLanguage', preferredLanguage);
    });
  }


  ionSplitPaneVisibleEvent(event: any) {
    this.ionSplitPaneVisible = event.detail.visible;
    this.cdr.detectChanges();
  }
}
