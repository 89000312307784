import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Subject} from 'rxjs';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService implements OnDestroy {

  pusher: any;
  questionsChannel: any;
  seminarChannel: any;

  constructor() {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true
    });
    this.questionsChannel = this.pusher.subscribe('question');
    this.seminarChannel = this.pusher.subscribe('seminar');
  }

  ngOnDestroy(): void {
    this.pusher.disconnect();
  }
}
