import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NewQuestionsPage} from '../../pages/new-questions/new-questions.page';
import {QuestionsService} from '../../services/questions.service';
import {NbAccessChecker} from '@nebular/security';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title = '';
  unreadQuestions = true;

  constructor(private modalCtrl: ModalController,
              public questionsService: QuestionsService,
              public accessChecker: NbAccessChecker) {

  }

  ngOnInit() {
  }

  async openNewPageQuestions() {
    const modal = await this.modalCtrl.create({
      component: NewQuestionsPage,
      id: 'tinder-modal',
      backdropDismiss: true,
      canDismiss: true,
    });
    modal.present().then();
  }
}
