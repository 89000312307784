/* eslint-disable */
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";
import {NbAclService} from "@nebular/security";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject(false);

  constructor(private api: ApiService,
              private accessService: NbAclService) {
    const isLoggedIn = localStorage.getItem('role');
    this.isLoggedIn$.next(isLoggedIn !== null);
  }

  login(username: string, password: string) {
    return this.api.login(username, password).pipe(
      map(({Token}) => {
      this.isLoggedIn$.next(true);
      this.accessService.register('admin', 'guest', {
        control: ['new-questions', 'seminar']
      });
      localStorage.setItem('Token', Token);
      localStorage.setItem('role', 'admin');
    })
    );
  }

  logout() {
    localStorage.clear();
    this.isLoggedIn$.next(false);
  }
}
