import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {SwiperEvents} from 'swiper/types';
import {throwError} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {catchError, finalize} from 'rxjs/operators';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {QuestionsService} from '../../services/questions.service';
import {PusherService} from '../../services/pusher.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy, AfterViewInit {


  disableSubmitButton = false;
  newQuestionForm = new FormGroup({
    question: new FormControl('', [Validators.required, Validators.minLength(3)]),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    jobTitle: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  constructor(private cdr: ChangeDetectorRef,
              private api: ApiService,
              private toastController: ToastController,
              public questionsService: QuestionsService,
              private pusherService: PusherService,
              public translateService: TranslateService) {
    pusherService.seminarChannel.bind('status', (data) => {
      const updatedSeminar = JSON.parse(data);
      this.questionsService.updateSeminar(updatedSeminar);
    });
  }

  get question() {
    return this.newQuestionForm.get('question');
  }

  get name() {
    return this.newQuestionForm.get('name');
  }

  get jobTitle() {
    return this.newQuestionForm.get('jobTitle');
  }


  ngOnInit() {
  }


  ngAfterViewInit() {

  }


  ngOnDestroy() {
  }

  onSwiper(eventParams: Parameters<SwiperEvents['afterInit']>) {
    // console.log(eventParams);
  }

  onSlideChange() {
  }

  submitLoginForm() {
    if (this.newQuestionForm.invalid) {
      this.newQuestionForm.markAllAsTouched();
      return;
    }
    this.disableSubmitButton = true;
    this.api.postQuestion(this.name.value,
      this.jobTitle.value,
      this.question.value)
      .pipe(
        finalize(() => {
          this.disableSubmitButton = false;
        }),
        catchError(err => {
          this.toastController.create({
            message: `[${err.status}] ${err.statusText}`,
            duration: 2000,
            color: 'danger',
            position: 'top'
          }).then(t => t.present());
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.newQuestionForm.reset();
        this.toastController.create({
          message: this.translateService.instant('home.questionSent'),
          duration: 2000,
          color: 'success',
          position: 'top'
        }).then(t => t.present());
      });
  }
}
