import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {IonicModule} from '@ionic/angular';
import {TimeAgoPipe} from './pipes/time-ago.pipe';


const COMPONENTS = [HeaderComponent];
const PIPES = [TimeAgoPipe];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [...COMPONENTS, ...PIPES]
})

export class SharedModule {
}
