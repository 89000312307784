import {Injectable} from '@angular/core';


import {NbRoleProvider} from '@nebular/security';
import {Observable, of} from 'rxjs';

@Injectable()
export class RoleProvider implements NbRoleProvider {

  constructor() {
  }

  getRole(): Observable<string> {
    const role = localStorage.getItem('role');
    return of(role ?? 'guest');
  }
}
