import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-question-cell',
  templateUrl: './question-cell.component.html',
  styleUrls: ['./question-cell.component.scss'],
})
export class QuestionCellComponent implements OnInit {
  @Input() question = ''

  constructor() {
  }

  ngOnInit() {
  }

}
