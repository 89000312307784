import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {QuestionsService} from '../../services/questions.service';
import {finalize, map} from 'rxjs/operators';
import {SeminarQuestion} from "../../services/api.service";

@Component({
  selector: 'app-new-questions',
  templateUrl: './new-questions.page.html',
  styleUrls: ['./new-questions.page.scss'],
})
export class NewQuestionsPage implements OnInit {
  loading = false;
  questions$ = this.questionService.newQuestions$;

  constructor(private cdr: ChangeDetectorRef,
              private modalCtrl: ModalController,
              private questionService: QuestionsService) {
    this.loading = true;
    this.questionService.getQuestions('new', 1, 10).pipe(
      finalize(() => {
        this.loading = false;
      }),
      map((questions) => this.questions$.next(questions.SeminarQuestions))
    ).subscribe();
  }

  ngOnInit() {
  }

  handleMatch(ev: any, questionIndex: number) {
    // if (ev) {
    //   console.log('It\'s a match!');
    // } else {
    //   console.log('Maybe next time');
    // }
    this.nextQuestion(questionIndex);

    this.cdr.detectChanges();
  }

  nextQuestion(questionIndex: number) {
    this.questions$.next(this.questions$.value.filter((aQuestion, index) => index !== questionIndex));
  }

  changeQuestionStatus(status: number, oneQuestion: SeminarQuestion) {
    this.questionService.rateQuestion(status, oneQuestion.Id).subscribe();
  }

  checkButtonClicked(question: SeminarQuestion) {
    this.nextQuestion(this.questions$.value.findIndex((aQuestion) => aQuestion.Id === question.Id));
  }
}
