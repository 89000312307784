/* eslint-disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {shareReplay} from 'rxjs/operators';

const API_URL = environment.API_URL;

export interface Seminar {
  Name: string;
  IsActive: boolean;
  Id: string;
  CreatedAt: Date;
  UpdateAt?: any;
  DeleteAt?: any;
}

export interface SeminarQuestion {
  QuestionerName: string;
  QuestionerJobTitle: string;
  Question: string;
  Status: number;
  SeminarId: string;
  Id: string;
  CreatedAt: Date;
  UpdateAt?: any;
  DeleteAt?: any;
}

export interface SeminarQuestions {
  SeminarQuestions: SeminarQuestion[];
  HasNext: boolean;
  TotalCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
    this.http.get('/assets/icon/avatar.svg').subscribe();
  }


  getSeminars() {
    return this.http.get<Seminar[]>(API_URL + 'Seminars');
  }

  postQuestion(QuestionerName: string, QuestionerJobTitle: string, Question: string) {
    return this.http.post(`${API_URL}SeminarQuestions`, {
      QuestionerName,
      QuestionerJobTitle,
      Question
    });
  }

  putQuestion(Status: number, Id: string) {
    return this.http.put(`${API_URL}SeminarQuestions`, {
      Status,
      Id
    });
  }

  getQuestions(Status: 'new' | 'ordered' | 'discard', PageNumber, PageSize, SeminarId?: string) {
    return this.http.get<SeminarQuestions | null>(API_URL + 'SeminarQuestions', {
      params: {
        Status,
        PageSize,
        PageNumber,
        // SeminarId
      }
    }).pipe(shareReplay({refCount: true, bufferSize: 1}));
  }

  register() {
    return this.http.post(API_URL + 'register', {});
  }

  login(Name: string, Password: string) {
    return this.http.post<{ Token: string; RefreshToken: string }>(API_URL + 'Users/authenticate', {Name, Password});
  }

  setSeminarsIsActive(Id: string, IsActive: boolean, Seminar: Seminar) {
    return this.http.put(`${API_URL}Seminars/${Id}`, {...Seminar, IsActive});
  }
}
