import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PusherService} from './pusher.service';
import {ApiService, Seminar, SeminarQuestion} from './api.service';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  newQuestions$ = new BehaviorSubject<SeminarQuestion[]>([]);
  activeSeminars$ = new BehaviorSubject<Seminar>(null);

  constructor(private pusherService: PusherService,
              private api: ApiService) {
    this.pusherService.questionsChannel.bind('new', (data) => {
      this.newQuestions$.next(JSON.parse(data));
    });
  }

  getSeminars() {
    return this.api.getSeminars().pipe(
      tap((seminars: Seminar[]) => this.activeSeminars$.next(seminars[0])),
      tap((seminars: any[]) => {
        localStorage.setItem('seminars', JSON.stringify(seminars));
      }));
  }

  toggleSeminar(id: string, isActive: boolean, oneSeminar) {
    return this.api.setSeminarsIsActive(id, isActive, oneSeminar);
  }

  getQuestions(status: 'new' | 'ordered' | 'discard', pageNumber, pageSize, seminarId?: string) {
    // return this.activeSeminars$.pipe(
    //   switchMap((seminar) =>
    //     this.api.getQuestions(seminar.id, status, pageNumber, pageSize)));
    return this.api.getQuestions(status, pageNumber, pageSize, seminarId);
  }

  rateQuestion(status: number, id: string) {
    return this.api.putQuestion(status, id);
  }

  updateSeminar(updatedSeminar: any) {
    return this.activeSeminars$.next({...updatedSeminar, Name: updatedSeminar.Name, IsActive: updatedSeminar.IsActive});
  }
}
