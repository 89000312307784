import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HomePage} from './pages/home/home.page';
import {QuestionCellComponent} from './shared/question-cell/question-cell.component';
import {NewQuestionsPage} from './pages/new-questions/new-questions.page';
import {TinderCardComponent} from './shared/tinder-card/tinder-card.component';
import {IonicRatingComponentModule} from 'ionic-rating-component';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {RoleProvider} from './RoleProvider';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SwiperModule} from 'swiper/angular';
import {SharedModule} from './shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RequestsInterceptor} from './services/requests-interceptor.service';


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, HomePage, QuestionCellComponent, NewQuestionsPage, TinderCardComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicRatingComponentModule,
    NbSecurityModule.forRoot(
      {
        accessControl: {
          guest: {
            view: ['home'],
          },
          admin: {
            parent: 'guest',
            control: ['new-questions', 'seminar'],
          }
        }
      }
    ),
    SwiperModule,
    SharedModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: NbRoleProvider, useClass: RoleProvider},
    {provide: HTTP_INTERCEPTORS, useClass: RequestsInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
