import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {createGesture, Gesture, GestureConfig, IonCard, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {SeminarQuestion} from '../../services/api.service';


@Component({
  selector: 'app-tinder-card',
  templateUrl: './tinder-card.component.html',
  styleUrls: ['./tinder-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TinderCardComponent implements AfterViewInit {
  @ViewChild('theCard', {static: true}) hostElement: ElementRef<HTMLInputElement>;
  @Output() match: EventEmitter<any> = new EventEmitter();
  @Output() rated: EventEmitter<number> = new EventEmitter();
  @Output() discard: EventEmitter<boolean> = new EventEmitter();
  @Output() checkButton: EventEmitter<SeminarQuestion> = new EventEmitter();
  @Input() mode: 'no-questions' | 'question' = 'question';
  @Input() question: SeminarQuestion;
  @Input() loading = false;

  constructor(private modalCtrl: ModalController,
              private cdr: ChangeDetectorRef,
              public translate: TranslateService) {
  }


  ngAfterViewInit() {
    this.initGesture().then();
    if (this.mode === 'no-questions') {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.question = {...this.question, Status: 5};
      this.cdr.detectChanges();
    }
  }

  async initGesture() {
    const style = this.hostElement.nativeElement.style;
    const windowWidth = window.innerWidth;
    const options: GestureConfig = {
      el: this.hostElement.nativeElement,
      gestureName: 'tinder-swipe',
      onStart: (g) => {
        style.transition = 'none';
        style.zIndex = '20000';
        this.hostElement.nativeElement.classList.add('tinder-swipe');
        if (this.question.Status === 0) {
          this.hostElement.nativeElement.classList.add('tinder-shake');
          setTimeout(() => {
            this.hostElement.nativeElement.classList.remove('tinder-shake');
          }, 830);
          return;
        }
        this.cdr.detectChanges();
      },
      onMove: (ev) => {
        if (this.question.Status === 0) {
          return;
        }
        style.transform = `translateX(${ev.deltaX}px) rotate(${
          ev.deltaX / 20
        }deg)`;
        style.zIndex = '20000';
        if (ev.deltaX > windowWidth / 2) {
        }
        this.cdr.detectChanges();
      },
      onEnd: (ev) => {
        if (this.question.Status === 0) {
          return;
        }
        style.transition = '0.3s ease-out';
        /*       this.modalCtrl.getTop().then((modal) => {
                 if (modal) {
                   modal.dismiss();
                 }
               });*/
        if (ev.deltaX > windowWidth / 4) {
          style.transform = `translateX(${windowWidth * 1.5}px)`;
          this.match.emit(true);
        } else if (ev.deltaX < -windowWidth / 4) {
          style.transform = `translateX(-${windowWidth * 1.5}px)`;
          this.match.emit(false);
        } else {
          style.transform = '';
        }

        if (this.mode === 'no-questions') {
          this.modalCtrl.dismiss();
        }
        this.hostElement.nativeElement.classList.remove('tinder-swipe');
        this.cdr.detectChanges();

      },
    };
    const gesture: Gesture = await createGesture(options);
    gesture.enable();
  }

  rejectQuestion(result: boolean) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.question = {...this.question, Status: -1};
    this.cdr.detectChanges();
    this.discard.emit(result);
  }


  onRatingChange(rating: number) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.question = {...this.question, Status: rating};
    this.cdr.detectChanges();
    this.rated.emit(rating);
  }

  checkButtonClicked(question: SeminarQuestion) {
    this.checkButton.emit(question);
  }
}
