import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatDistanceToNow} from 'date-fns';

import arLocale from 'date-fns/locale/ar';
import enLocale from 'date-fns/locale/en-US';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private translateService: TranslateService
  ) {
  }

  transform(value: string, ...args: unknown[]): string {
    return formatDistanceToNow(new Date(value + '+00:00'), {
      addSuffix: true,
      locale: this.translateService.currentLang === 'ar' ? arLocale : enLocale,
    });
  }

}
